
import CountUp from 'react-countup';
import FranchiseSubscriptionCard from '../../page/Franchise-SubscriptionCard';

const subTitle = "START TO SUCCESS";
const title = "Achieve Your Goals With Stargleam Education";


const achievementList = [
    {
        count: '30',
        desc: 'Years of Language Education Experience',
    },
    {
        count: '3084',
        desc: 'Learners Enrolled in Edukon Courses',
    },
    {
        count: '330',
        desc: 'Qualified Teachers And Language Experts',
    },
    {
        count: '2300',
        desc: 'Innovative Foreign Language Courses',
    },
]

const achieveList = [
    {
        imgUrl: 'assets/images/achive/01.png',
        imgAlt: 'achive thumb rajibraj91 rajibraj',
        title: 'Start Teaching Today',
        desc: 'Seamlessly engage technically sound coaborative reintermed goal oriented content rather than ethica',
        btnText: 'Become A Instructor',
        siteLink: '#',
    },
    {
        imgUrl: 'assets/images/achive/02.png',
        imgAlt: 'achive thumb rajibraj91 rajibraj',
        title: 'If You Join Our Course',
        desc: 'Seamlessly engage technically sound coaborative reintermed goal oriented content rather than ethica',
        btnText: 'Register For Free',
        siteLink: '#',
    },
]


const Achievement = () => {
    return (
        <div className="achievement-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="counter-part mb-4">
                    </div>
                    <div className="achieve-part">
                    <FranchiseSubscriptionCard />

                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Achievement;