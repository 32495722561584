import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import { Form } from "react-bootstrap";

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle =
  "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Submit";

const contactList = [
  {
    imgUrl: "assets/images/icon/01.png",
    imgAlt: "contact icon",
    title: "Office Address",
    desc: "Gold,Building 3, Chiswick Park, 566 Chiswick High Road, London, England, W4 5YA",
  },
  {
    imgUrl: "assets/images/icon/02.png",
    imgAlt: "contact icon",
    title: "Phone number",
    desc: "+44 - 7726018288",
  },
  {
    imgUrl: "assets/images/icon/03.png",
    imgAlt: "contact icon",
    title: "Send email",
    desc: "info@stargleameducation.com",
  },
  {
    imgUrl: "assets/images/icon/04.png",
    imgAlt: "contact icon",
    title: "Our website",
    desc: "www.stargleameducation.com",
  },
];

const ContactPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Get In Touch With Us"} curPage={"Contact Us"} />
      <div className="map-address-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{subTitle}</span>
            <h2 className="title">{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="contact-wrapper">
                  {contactList.map((val, i) => (
                    <div className="contact-item" key={i}>
                      <div className="contact-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="contact-content">
                        <h6 className="title">{val.title}</h6>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-12">
                <GoogleMap />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section padding-tb">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form className="contact-form">
              <div className="form-group">
                <input type="text" name="name" placeholder="Your Name *" />
              </div>
              <div className="form-group">
                <input type="text" name="email" placeholder="Your Email *" />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="number"
                  placeholder="Mobile Number *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="student-name"
                  placeholder="Student Name*"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="student-current-school"
                  placeholder="Student Current School"
                />
              </div>
              <div className="form-group"> 
              <select placeholder="">
              <option selected>Student Current Year Group</option>
              <option value="1 Year">1 Year</option>
              <option value="2 Year">2 Year</option>
              <option value="3 Year">3 Year</option>
              <option value="4 Year">4 Year</option>
              <option value="5 Year">5 Year</option>
              <option value="6 Year">6 Year</option>
              <option value="7 Year">7 Year</option>
              <option value="8 Year">8 Year</option>
              <option value="9 Year">9 Year</option>
              <option value="GCSE">GCSE</option>
              <option value="A level">A level</option>
              </select>
              </div>

              <div className="form-group w-100">
                <textarea
                  rows="8"
                  type="text"
                  placeholder="Tution Detail"
                ></textarea>
              </div>
              <div className="form-group"> 
              <select placeholder="">
              <option selected>Courses/ Subjects</option>
              <option value="Wordbank">Wordbank</option>
              <option value="Creative Writing">Creative Writing</option>
              <option value="Comprehension">Comprehension</option>
              <option value="SPAG">SPAG</option>
              <option value="11+ Coaching - Y4">11+ Coaching - Y4</option>
              <option value="11+ Coaching - Y5">11+ Coaching - Y5</option>
              <option value="Verbal Reason">Verbal Reason</option>
              <option value="Non Verbal Reason">Non Verbal Reason</option>
              <option value="GCSE / A Levels Math">GCSE / A Levels Math</option>
              <option value="GCSE / A Levels English">GCSE / A Levels English</option>
              <option value="GCSE / A Levels Physics">GCSE / A Levels Physics</option>
              <option value="GCSE / A Levels Biology">GCSE / A Levels Biology</option>
              <option value="GCSE / A Levels Chemistry">GCSE / A Levels Chemistry</option>
              <option value="7+">7+</option>
              <option value="8+">8+</option>
              <option value="10+">10+</option>
              <option value="12+">12+</option>
              <option value="13+">13+</option>
              <option value="ISEB board exam">ISEB board exam</option>
              </select>
              </div>
              <div className="form-group">
                <select >
                <option selected>Tuition frequency</option>
              <option value="1 Lesson per Week">1 Lesson per Week</option>
              <option value="2 Lesson per Week">2 Lesson per Week</option>
              <option value="3 Lesson per Week">3 Lesson per Week</option>
              <option value="4 Lesson per Week">4 Lesson per Week</option>
              <option value="5 Lesson per Week">5 Lesson per Week</option>
                </select>
              </div>
              <div className="form-group">
                <select >
                <option selected>Tuition Duration</option>
              <option value="1 Hour">1 Hour</option>
              <option value="1.5 Hour">1.5 Hour</option>
              <option value="2 Hour">2 Hour</option>
              <option value="2.5 Hour">2.5 Hour</option>
              <option value="3 Hour">3 Hour</option>
                </select>
              </div>
             
              <div className="form-group">
                <select >
                <option selected>How Did You Hear About Us</option>
              <option value="Another Parent">Another Parent</option>
              <option value="Facebook">Facebook</option>
              <option value="Google">Google</option>
              <option value="Local News">Local News</option>
              <option value="Other">Other</option>
                </select>
              </div>

              <div className="form-group w-100">
                <textarea
                  rows="8"
                  type="text"
                  placeholder="Address"
                ></textarea>
              </div>
              <div className="space-y-2">
                <div>

                  <h5 htmlFor="" className="m-2" style={{height: '40px'}}>Group Session</h5>
                

                  <label className="form-check-label" style={{width: '100px',}}>
    <input type="checkbox" className="form-check-input rounded-circle shadow-none" defaultChecked />
    <span>Yes</span>
</label>
    
<label class="form-check-label">
  <input type="checkbox" class="form-check-input rounded-circle shadow-none" defaultChecked />
 <span>No</span>
</label>
                </div>
              </div>
             
                
              
              <div className="form-group">
                <input
                  type="text"
                  name="student-current-school"
                  placeholder="Enter Captcha Here"
                />
              </div>
              <div className="form-group w-100 text-center">
                <button className="lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
