
import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "World-class Instructors";
const title = "Classes Taught By Real Creators";

const instructorList = [
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2023/03/WhatsApp-Image-2023-01-21-at-20.00.25-200x200.jpeg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Arman',
        degi: 'Maths Tutor',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2021/10/83E52C9D-896F-4E5D-9597-3B6545FE0FBA-200x200.jpeg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Eric',
        degi: 'Maths Tutor',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2021/06/Wendy-1-scaled-200x200.jpg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Wendy-Anne Daloz',
        degi: '11+/12+/ GCSE Maths Teacher',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2021/01/WhatsApp-Image-2021-06-28-at-05.18.29-200x200.jpeg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Pragnya',
        degi: 'Maths And Science Tutor',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2021/01/D2D0F39A-DF60-43F5-960B-9B7927A90AAD-200x200.jpeg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Hugo',
        degi: 'Maths Tutor',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2022/07/IMG_0078-200x200.png',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Caroline',
        degi: '',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2022/07/88357126-2ACD-47EC-AA78-89664AB2AC11-200x200.jpeg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Maureen',
        degi: 'Maths And English Tutor',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'https://stargleameducation.net/wp-content/uploads/2023/03/WhatsApp-Image-2023-03-01-at-15.13.19-200x200.jpeg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Parul',
        degi: 'Business Manager (AYLESBURY)',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
]


const Instructor = () => {
    return (
        <div className="instructor-section padding-tb section-bg">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
                        {instructorList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="instructor-item">
                                    <div className="instructor-inner">
                                        <div className="instructor-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="instructor-content">
                                            <Link to="/team-single"><h4>{val.name}</h4></Link>
                                            <p>{val.degi}</p>
                                            <Rating />
                                        </div>
                                    </div>
                                    <div className="instructor-footer">
                                        <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                            <li><i className="icofont-book-alt"></i> {val.courseCount}</li>
                                            <li><i className="icofont-users-alt-3"></i> {val.studentAnroll}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center footer-btn">
                        <p>Want to help people learn, grow and achieve more in life?<Link to="/team">Become an instructor</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Instructor;