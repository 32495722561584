import React, { useEffect, useState } from 'react'
import FranchiseSubscriptionCard from './Franchise-SubscriptionCard'
import PageHeader from '../component/layout/pageheader';
import Header from '../component/layout/header';
import Instructor from '../page/instructor'


const FranchiseSubscription = () => {
  return (
    <>
    <Header />
    <Instructor />
    
  
      </>
  )
}

export default FranchiseSubscription




   