import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import ImageUploading, { ImageListType } from "react-images-uploading";
import filePreviewSvg from "../assets/images/Preview/file-preview.svg";
import './BecomeInstructorUpload.css'

const BecomeInstructorPage = () => {
  const formStyle = {
    width: "100%",
    backgroundColor: "transparent",
    border: "2px solid #e6e3f1",
    borderRadius: "6px",
    lineHeight: "23px",
    padding: "10px 20px",
    fontSize: "14px",
    color: "#6b7385",
    marginBottom: "15px",
    boxShadow: "0 13px 14px 0 rgba(129,104,145,.05)",
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [images, setImages] = useState([]);
  const maxNumber = 69;

  const onChange = (imageList) => {
    // Update the images state with the new imageList
    setImages(imageList);
  };

  return (
    <Row>
      <Col md={8} style={{ width: "83rem" }}>
        <h3 className="mb-5">Become Instructor</h3>

        <Form>
          <Form.Group controlId="formTutorFirstName" className="mb-3">
            <Form.Label className="d-flex">Tutor First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="tutor First Name"
              defaultValue="Tutor First Name"
              style={formStyle}
            />
          </Form.Group>

          <Form.Group controlId="formTutorLastName" className="mb-3">
            <Form.Label className="d-flex">Tutor Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="tutor Last Name"
              defaultValue="Tutor Last Name"
              style={formStyle}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label className="d-flex">Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              defaultValue="Student@example.com"
              style={formStyle}
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mb-3">
            <Form.Label className="d-flex">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              style={formStyle}
            />
          </Form.Group>

          <Form.Group controlId="formPhone" className="mb-3">
            <Form.Label className="d-flex">Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone No"
              style={formStyle}
            />
          </Form.Group>

          <Form.Group controlId="formAddress" className="mb-3">
            <Form.Label className="d-flex">Address</Form.Label>
            <Form.Control type="text" placeholder="Adress" style={formStyle} />
          </Form.Group>

          <Form.Group controlId="formCity" className="mb-3">
            <Form.Label className="d-flex">City</Form.Label>
            <Form.Control type="text" placeholder="City" style={formStyle} />
          </Form.Group>

          <Form.Group controlId="formPostcode" className="mb-3">
            <Form.Label className="d-flex">Zipcode </Form.Label>
            <Form.Control type="text" placeholder="Zipcode" style={formStyle} />
          </Form.Group>

          <Form.Group controlId="formCountry" className="mb-3">
            <Form.Label className="d-flex">Country </Form.Label>
            <Form.Control type="text" placeholder="Country" style={formStyle} />
          </Form.Group>

          <div className="upload-container">
            <h4 className="mb-4">Upload</h4>
            <div className="upload-area">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={['jpg', 'gif', 'png']}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    <button
                      type="button"
                      className="choose-file-button"
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Choose File...
                    </button>
                    <div className="image-preview-container">
                      {imageList.length > 0 ? (
                        imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image.data_url} alt={`uploaded-${index}`} />
                            <div className="image-item__btn-wrapper">
                              <button 
                                type="button" 
                                onClick={() => onImageRemove(index)} 
                                className="remove-btn"
                              >
                                ✖
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="placeholder-images">
                          <img
                            src={filePreviewSvg}
                            alt="File preview small"
                            className="preview-small"
                          />
                          <img
                            src={filePreviewSvg}
                            alt="File preview large"
                            className="preview-large"
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </ImageUploading>
            </div>
          </div>



          <Button variant="primary" type="submit" className="mt-2">
            Save
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default BecomeInstructorPage;
